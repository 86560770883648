<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-2 max-w-full flex-grow flex-1">
          <h3 class="text-base text-blueGray-700">
            <i class="pi pi-barcode"></i> Commissions/Fees
          </h3>
        </div>
        <div
          class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
        >
          <button
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            :disabled="loading"
            @click="toggleModal('filter', true)"
          >
            <i class="pi pi-filter" style="font-size: 10px;"></i> filter
          </button>

          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 text-xs px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            @click="$emit('create', {})"
          >
            <i class="pi pi-plus-circle" style="font-size: 10px;"></i> Create new
          </button>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead class="thead-light">
          <tr>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left"
            >
              Role
            </th>
            <th
              class="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left"
            >
              Name
            </th>
            <th
              class="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left"
            >
              Type
            </th>
            <th
              class="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left"
            >
              Transaction Type
            </th>
            <th
              class="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left min-w-140-px"
            >
              Amount
            </th>
            <th
              class="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left min-w-140-px"
            >
              Date
            </th>
            <th
              class="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left min-w-140-px"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody v-if="has('fees.show')">
          <template v-if="!loaders().fetchFees">
            <tr v-for="(fee, i) in feeList" :key="i" class="cursor-pointer">
              <th
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                :class="{
                  'text-red-500': !fee?.is_active
                }"
              >
                <div class="flex capitalize" @click="handleView(fee)">
                  {{ fee.role?.name }} 
                </div>
              </th>
              <th
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
              >
                {{ fee.name }}
              </th>
              <td
                class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                {{ fee.type }}
              </td>
              <td
                class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                {{ fee.transaction_type }}
              </td>
              <td
                class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <div class="flex items-center">
                  <small class="text-xs">{{ fee.rate?.toLowerCase() === "flat" ? formatter("NGN").format(fee.amount) : `${fee.amount}%` }}</small>
                </div>
              </td>
              <td
                class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                {{ moment(fee.created_at).format("DD MMM YY") }}
              </td>
              <td
                class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <button
                  class="bg-emerald-500 text-white active:bg-emerald-600 text-xs px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  @click="handleView(fee)"
                >
                  <i class="pi pi-eye"></i> see more
                </button>
                <button
                  class="bg-indigo-500 text-white active:bg-indigo-600 text-xs px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  @click="$emit('create', fee)"
                  v-if="has('fees.update')"
                >
                  <i class="pi pi-file-edit"></i> edit
                </button>
                <button
                  class="bg-red-500 text-white active:bg-red-600 text-xs px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  @click.stop="handleDeleteFee(fee)"
                  v-if="has('fees.destroy')"
                >
                <i class="pi pi-times-circle"></i> delete
                </button>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="7" rowspan="7">
                <div class="flex items-center justify-center w-full py-12 h-30">
                  <span class="loader block"></span>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <permission-err title="fees" v-if="!has('fees.show')"></permission-err>
      <empty-list v-if="(!fees.data || fees.data.length === 0) && !loaders().fetchFees && has('fees.show')"></empty-list>

    </div>
    <div class="flex flex-wrap items-center">
      <div class="relative w-full px-4 max-w-full flex-grow flex-1">
        <circular-pagination :current_page="fees.current_page" :last_page="fees.last_page" :size="pageSize" @paginate="pageChange" ></circular-pagination>
      </div>
    </div>

    <transition name="bounce" :duration="{ enter: 500, leave: 800 }">
      <div v-if="modals.fee" style="width: 100%;" @click.self="$_close_all_modal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
        <div class="relative w-auto z-50">
          <!--content-->
          <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <!--header-->
            <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 class="text-lg capitalize">
                <i class="pi pi-info-circle"></i> {{  fee?.name }} information
              </h3>
              <button 
                class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
                v-on:click="toggleModal('fee', false)"
              >
                <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            <!--body-->
            <recursive-table 
              :data="fee"
              :excludeAll="['id']"
              :exclude="['role_id']"
            />
            <div class="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b">            
              <button 
                class="text-red-500 background-transparent px-3 py-1 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
                v-on:click="toggleModal('fee', false)"
              >
                close
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div v-if="modals.fee" @click="$_close_all_modal" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-30 h-screen top-0 left-0 bg-black"></div>
    
    <filter-modal 
      :selects="{
        transaction_type: transactionList, 
        role: (roles || []).map( r => r.name),
        type: ['COMMISSION', 'CONVENIENCE'],
        rate: ['FLAT', 'PERCENTAGE'],
        frequency: ['INSTANT', 'DAILY', 'WEEKLY', 'MONTHLY', 'YEARLY']
      }"
      :dates="{
        from: 'Start Date',
        to: 'End Date'
      }"
      :searchable="true"
      :sizable="true"
      :visible="modals.filter"
      @close="modals.filter=false" 
      title="commission"
      @filter="filter"
      :loading="loading || loaders().getCommission"
    ></filter-modal>

  </div>
</template>

<script>
import CircularPagination from "@/components/Paginations/CircularPagination.vue";
import { mapActions, mapGetters } from 'vuex';
import { formatter } from "../../utils/formatter.util";
import moment from "moment"
import { confirmAction, transactionList } from "@/utils";
import RecursiveTable from '@/components/Tables/RecursiveTable.vue';
import EmptyList from "@/components/Cards/EmptyList.vue";
import PermissionErr from "@/components/Cards/PermissionErr.vue";
import FilterModal from "@/components/Modals/FilterModal.vue";
import { modal } from '@/mixins'

export default {
  components: {
    CircularPagination,
    RecursiveTable,
    EmptyList,
    PermissionErr,
    FilterModal
  },
  props: ["fees", "param"],
  mixins: [modal],
  created() {
    this.moment = moment
    this.formatter = formatter
  },
  data() {
    return {
      feeList: [],
      fee: {},
      pagination: {},
      modals: {},
      transactionList
    }
  },
  watch: {
    fees: function (n) {
      this.feeList = n?.data || []
    },
    '$store.state.fees.data': function (n) {
      this.feeList = n
    }
  },
  computed: {
    ...mapGetters(["roles"]),
  },
  mounted() {
      this.feeList = this.fees?.data || []
  },
  methods: {
    ...mapActions(["fetchFees", "deleteFee"]),
    ...mapGetters(["loaders"]),
    pageChange(pagination) {
      this.pagination = pagination;
      this.fetchNewPageFee();
    },
    filter(params) {
      this.params = params;
      this.fetchNewPageFee(params);
    },
    fetchNewPageFee(p={}) {
      const params = {
        ...this.pagination,
        get_csv: this.get_csv,
        ...this.params,
        ...p
      }

      this.fetchFees(params)
    },
    toggleModal(modalName, isvisible) {
      this.modals[modalName] = isvisible
    },
    async handleDeleteFee(fee) {
      confirmAction({
        confirmText: `Remove ${fee.name}`,
        confirmTextHelper: `Kindly confirm you want to delete this fee`,
        successText: `You have successfully deleted ${fee.name} ${ fee.type} fee`,
        cancelText: `Removal of fee aborted`
      }, () => {
        this.deleteFee(fee).then(() => {})
      });
    },
    handleView(fee) {
      this.fee = fee;
      this.toggleModal('fee', true)
    }
  }
}
</script>

<style scoped>
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>