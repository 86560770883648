import AccountAction from "./account.action";
import LoaderAction from "./loader.action";
import NotificationAction from "./notification.action";
import TransactionAction from "./transaction.action";
import UserAction from "./user.action";
import InsightAction from "./insight.action";
import PlanAction from "./plan.action";
import ConfigAction from "./config.action";
import SettingsAction from "./settings.action";
import BankAccountAction from "./bankAccount.action";
import WithdrawAction from "./withdraw.action";
import MetricsAction from "./metrics.action";
import CurrencyAction from "./currency.action";
import RoleAction from "./role.action";
import BusinessAction from "./business.action";
import CampaignAction from "./campaign.action";
import FeeAction from "./fee.action";
import AppAction from "./app.action";
import VasAction from "./vas.action";
import SubscriptionAction from "./subscription.action";
import DisputeAction from "./dispute.action";

const actions = {
    ...SettingsAction,
    ...TransactionAction,
    ...UserAction,
    ...PlanAction,
    ...LoaderAction,
    ...NotificationAction,
    ...AccountAction,
    ...InsightAction,
    ...ConfigAction,
    ...BankAccountAction,
    ...WithdrawAction,
    ...MetricsAction,
    ...CurrencyAction,
    ...RoleAction,
    ...BusinessAction,
    ...CampaignAction,
    ...FeeAction,
    ...AppAction,
    ...VasAction,
    ...SubscriptionAction,
    ...DisputeAction
};

export default actions;