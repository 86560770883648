<template>
    <div class="relative flex w-full">
        <div v-if="show" style="width: 100%;" @click.self="handleClose" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
            <div class="relative w-auto z-50">
                <!--content-->
                <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <!--header-->
                    <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                        <h3 class="text-xl font-semibold">
                            Plan Information
                        </h3>
                        <button 
                        class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
                        v-on:click="handleClose"
                        >
                        <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                            ×
                        </span>
                        </button>
                    </div>
                    <!--body-->
                    <div class="relative p-6 flex-auto">
                        <div class="w-full px-4">
                            <div class="flex flex-wrap">
                                <div class="w-full">
                                    <div class="relative w-full mb-3">
                                    <table class="items-center w-full bg-transparent border-collapse">
                                        <thead class="thead-light">
                                        </thead>
                                        <tbody>
                                        <tr >
                                            <th
                                            class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-2 text-left"
                                            >
                                            Name
                                            </th>
                                            <th
                                            class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-2 text-left"
                                            >
                                            {{ userPlan.name }}
                                            </th>
                                        </tr>
                                        <tr >
                                            <th
                                            class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-2 text-left"
                                            >
                                            Customer Name
                                            </th>
                                            <th
                                            class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-2 text-left"
                                            >
                                            {{ userPlan.user?.name }}
                                            </th>
                                        </tr>
                                        <tr >
                                            <th
                                            class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-2 text-left"
                                            >
                                            Status
                                            </th>
                                            <th
                                            class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-2 text-left"
                                            >
                                            {{ userPlan.status }}
                                            </th>
                                        </tr>
                                        <tr >
                                            <th
                                            class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-2 text-left"
                                            >
                                            Plan Currency
                                            </th>
                                            <th
                                            class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-2 text-left"
                                            >
                                            {{ userPlan.currency }}
                                            </th>
                                        </tr>
                                        <tr >
                                            <th
                                            class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-2 text-left"
                                            >
                                            User Request Currency
                                            </th>
                                            <th
                                            class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-2 text-left"
                                            >
                                            {{ userPlan.base_currency }}
                                            </th>
                                        </tr>
                                        <tr >
                                            <th
                                            class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-2 text-left"
                                            >
                                            Amount in User Currency
                                            </th>
                                            <th
                                            class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-2 text-left"
                                            >
                                            {{ userPlan.base_currency_amount }}
                                            </th>
                                        </tr>
                                        <tr >
                                            <th
                                            class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-2 text-left"
                                            >
                                            Duration
                                            </th>
                                            <th
                                            class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-2 text-left"
                                            >
                                            {{ userPlan.duration }} {{ userPlan.duration_unit }}(s)
                                            </th>
                                        </tr>

                                        <tr >
                                            <th
                                            class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-2 text-left"
                                            >
                                            Funding Source
                                            </th>
                                            <th
                                            class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-2 text-left"
                                            >
                                            {{ userPlan.funding_source }}
                                            </th>
                                        </tr>

                                        <tr >
                                            <th
                                            class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-2 text-left"
                                            >
                                            Rate
                                            </th>
                                            <th
                                            class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-2 text-left"
                                            >
                                            {{ userPlan.rate }}
                                            </th>
                                        </tr>

                                        <tr >
                                            <th
                                            class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-2 text-left"
                                            >
                                            Reference
                                            </th>
                                            <th
                                            class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-2 text-left"
                                            >
                                            {{ userPlan.reference }}
                                            </th>
                                        </tr>
                                        <tr >
                                            <th
                                            class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-2 text-left"
                                            >
                                            Request Date
                                            </th>
                                            <th
                                            class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-2 text-left"
                                            >
                                            {{ userPlan.created_at }}
                                            </th>
                                        </tr>
                                        <tr >
                                            <th
                                            class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-2 text-left"
                                            >
                                            User Address
                                            </th>
                                            <th
                                            class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-2 text-left"
                                            >
                                            {{ userPlan.user?.profile?.address }}
                                            </th>
                                        </tr>
                                        <tr >
                                            <th
                                            class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-2 text-left"
                                            >
                                            Comment
                                            </th>
                                            <th
                                            class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs p-2 text-left"
                                            >
                                            {{ userPlan.comment }}
                                            </th>
                                        </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <map-pan :longitude="location.longitude" :latitude="location.latitude" v-if="isMapVisible" ></map-pan>
                            <div class="m-4 text-sm" v-else>
                                Live Location:  {{ location.longitude }}, {{ location.latitude }}
                            </div>
                        </div>
                    </div>
                    <!--footer-->
                    <div class="flex items-center justify-between p-4 border-t border-solid border-blueGray-200 rounded-b">
                        <button 
                        class="text-emerald-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
                        v-on:click="toggleMapVisibility"
                        >
                        {{ isMapVisible ? "Hide": "Show" }} Map
                        </button>

                        <button 
                        class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
                        v-on:click="handleClose"
                        >
                        Close
                        </button>
                    </div>
                </div>
            </div>
            
        </div>
        <div v-if="show" style="width: 100%; opacity: 0.25;" @click="handleClose" class="opacity-25 fixed inset-0 z-30 h-screen top-0 left-0 bg-black"></div>
    </div>
</template>

<script>
import MapPan from "@/components/Maps/MapPan.vue";
import { modal } from '@/mixins'

export default {
    props: [ "userPlan", "show" ],
    components: {
        MapPan
    },
    mixins: [modal],
    methods: {
        handleClose(e) {
            this.$emit("close", e)
        },
        toggleMapVisibility() {
            this.isMapVisible = !this.isMapVisible
        }
    },
    watch: {
        userPlan: function(userPlan) {
            const { longitude = 0, latitude = 0 } = userPlan.user.profile || {}
            this.location = {
                longitude,
                latitude
            }
        }
    },
    data() {
        return {
            location: {
                longitude: 0,
                latitude: 0
            },
            isMapVisible: false
        }
    },
    mounted() {
        if (this.userPlan?.user?.profile) {
            const { longitude = 0, latitude = 0 } = this.userPlan.user.profile
            this.location = {
                longitude,
                latitude
            }
        }
    }
}
</script>