<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center justify-between">
        <div class="relative px-4">
          <h3
            class="text-lg text-blueGray-700 capitalize"
          >
          <i class="pi mx-2 text-sm pi-credit-card"></i> {{ category ? snakeToTitleCase(category) : '' }} Transactions
          </h3>
        </div>

        <div class="flex mb-3">
          <label class="inline-flex items-center cursor-pointer mr-2">
            <span class="ml-2 text-xs text-emerald-600 mr-2">hide commission</span>
            <input id="opt-out" type="checkbox" v-model="hide_commission" @change="filter" class="form-checkbox border-0 text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 rounded">
            <!-- <i v-if="plan.is_active" class="fas fa-eye mr-1 text-sm text-emerald-600"></i>
            <i v-else class="fas fa-eye-slash mr-1 text-sm text-emerald-600"></i>   -->
          </label>
          <button
            class="bg-orange-500 text-white active:bg-orange-600 text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
            type="button" 
            :disabled="loading"
            @click="$emit('create')"
            v-if="canCreate"
          >
          <i class="pi pi-plus text-xs" style="font-size: 10px;"></i> Create New
          </button>
          <button
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
            type="button" 
            :disabled="loading"
            @click="toggleModal('filter', true)"
          >
          <i class="pi pi-filter text-xs" style="font-size: 10px;"></i> Filter
          </button>
          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
            type="button" 
            @click="() => {get_csv=true;fetchNewTransactionPage()}"
            :disabled="!has('transaction.view') || loading || loaders().fetchTransactions" 
          >
          <i class="pi pi-download text-xs" style="font-size: 10px;"></i> Download
          </button>
        </div>

      </div>
    </div>


    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Name
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Type
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Amount
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Balance <br/>
              <small>(Before / After)</small>
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Status
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Charges
            </th>
            
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Date
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Details
            </th>
          </tr>
        </thead>
        <tbody v-if="has('transaction.view')">
          <template v-if="!loaders().fetchTransactions">
            <tr 
              v-for="(trn, i) in transactions().data" 
              :key="i"
            >
              <th
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
              >
                <span class="font-light">
                  {{ trn.user?.name }} <br/>
                  <small>{{ trn.user?.email }}</small>
                </span>
              </th>

              <th
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
              >
                <span class="font-light lowercase">
                  {{ snakeToTitleCase(trn.category) }} 
                  <small v-if="['CREDIT', 'DEBIT'].includes(trn.type)"
                  class="uppercase"
                  :class="[
                    trn.type === 'CREDIT' ? 'text-emerald-600' : '',
                    trn.type === 'DEBIT' ? 'text-orange-500' : '',
                    trn.type === 'NONE' ? 'text-blueGray-600' : '',
                  ]"
                  >({{ {"CREDIT": "CR", "DEBIT": "DR"}[trn.type] }})</small>
                </span>
              </th>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <i 
                  class="fas mr-1"
                  :class="{
                    'fa-arrow-up text-emerald-500': trn.type === 'CREDIT',
                    'fa-arrow-down text-red-500': trn.type === 'DEBIT',
                    'fa-arrow-right text-blueGray-500': trn.type === 'NONE'
                  }"
                ></i>
                {{ formatter(trn.currency).format(trn.gross_amount) }}
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                {{ formatter(trn.currency).format(JSON.parse(trn.meta || "{}").balance_before || 0) }} / 
                {{ formatter(trn.currency).format(JSON.parse(trn.meta || "{}").balance || 0) }}
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 font-light"
              >
                <span 
                  class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
                  :class="{
                    'bg-emerald-300 text-emerald-600 ring-emerald-500/20': trn.status === 'SUCCESSFUL', 
                    'bg-red-200 text-red-600 ring-red-500/10': trn.status === 'FAILED',
                    'bg-blueGray-300 text-blueGray-600 ring-blueGray-500/10': trn.status === 'PENDING'
                  }"
                  @click="showTransaction(trn, true)"
                >
                  {{ trn.status }}
                </span>
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <div class="flex items-center">
                  <span class="mr-2">{{ trn.charge }}</span>
                </div>
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <div class="flex items-center">
                  <span class="mr-2">{{ moment(trn.created_at).format("DD MMM YY hh:mm") }}</span>
                </div>
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center"
                @click="showTransaction(trn)"
              >
                <!-- <trans-dropdown :data="{}" /> -->
                <span class="text-blueGray-500 py-1 px-3"><i class="fas fa-ellipsis-v"></i></span>
              </td>
            </tr>
            <!-- 
            <tr>
            <th
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center"
            >
              <img
                :src="react"
                class="h-12 w-12 bg-white rounded-full border"
                alt="..."
              />
              <span
                class="ml-3 font-bold"
                :class="[
                  color === 'light' ? 'text-blueGray-600' : 'text-white',
                ]"
              >
                React Material Dashboard
              </span>
            </th>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              $4,400 USD
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <i class="fas fa-circle text-teal-500 mr-2"></i> on schedule
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div class="flex">
                <img
                  :src="team1"
                  alt="..."
                  class="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow"
                />
                <img
                  :src="team2"
                  alt="..."
                  class="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"
                />
                <img
                  :src="team3"
                  alt="..."
                  class="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"
                />
                <img
                  :src="team4"
                  alt="..."
                  class="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"
                />
              </div>
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div class="flex items-center">
                <span class="mr-2">90%</span>
                <div class="relative w-full">
                  <div
                    class="overflow-hidden h-2 text-xs flex rounded bg-teal-200"
                  >
                    <div
                      style="width: 90%;"
                      class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-teal-500"
                    ></div>
                  </div>
                </div>
              </div>
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
            >
              <table-dropdown />
            </td>
          </tr>
           -->
          </template>
          <template v-else>
            <tr>
              <td colspan="8" rowspan="8">
                <div class="flex items-center justify-center w-full py-12 h-30">
                  <span class="loader block"></span>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <permission-err v-if="!has('transaction.view')"></permission-err>
      <empty-list v-if="(!transactions().data || transactions().data.length === 0) && !loaders().fetchTransactions && has('transaction.view')"></empty-list>
    </div>
    <div class="flex flex-wrap items-center">
      <div class="relative w-full px-4 max-w-full flex-grow flex-1">
        <circular-pagination :current_page="transactions().current_page" :last_page="transactions().last_page" :size="pageSize" @paginate="pageChange" ></circular-pagination>
      </div>
    </div>
    <transition-group>
      <transition name="bounce" :duration="{ enter: 500, leave: 800 }">
        <div v-if="modals.transaction" style="width: 100%;" @click.self="$_close_all_modal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
          <div class="relative w-auto z-50">
            <!--content-->
            <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <!--header-->
              <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                <h3 class="text-xl font-semibold">
                  Transaction
                </h3>
                <button 
                  class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
                  v-on:click="toggleModal('transaction', false)"
                >
                  <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              <!--body-->
              <div class="relative p-6 flex-auto">
                <recursive-table :data="transaction" :exclude="['id']" :copies="['reference', 'uuid']" />
                <div v-if="confirmReversal">
                  <hr/>
                  <div class="flex flex-wrap mt-4 items-center">
                    <div class="w-8/12 pl-4">
                      <div class="relative w-full flex">
                        <label
                          class="block text-blueGray-600 text-xs mb-2"
                          htmlFor="grid-password"
                        >
                          Enter Reference 
                        </label>
                        <input
                          type="text"
                          class="border-0 px-3 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-xs shadow focus:outline-none w-full ease-linear transition-all duration-150"
                          v-model="confirmReference"
                          placeholder="Transaction Reference"
                        />
                      </div>
                    </div>

                    <div class="w-4/12 px-4">
                      <button
                        class="bg-emerald-500 text-white active:bg-emerald-600 text-xs px-3 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150 w-full"
                        type="button" 
                        @click="reverseTransaction" 
                        :disabled="loaders().reverse || !confirmReference"
                        v-if="has('wallet.debit.ability')"
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!--footer reverseTransaction-->
              <div class="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b">

                <button 
                  class="text-emerald-600 rounded bg-emerald-300 px-3 py-1 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  @click="handleConfirmWithdraw"
                  :disabled="loaders().confirmWithdraw"
                  v-if="showConfirmation && has('confirm.withdraw.ability')"
                >
                  Confirm
                </button>

                <button 
                  class="text-red-500 rounded bg-red-200 px-3 py-1 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  @click="handleDeclineWithdraw"
                  :disabled="loaders().declineWithdraw"
                  v-if="showConfirmation && has('confirm.withdraw.ability')"
                >
                  Decline
                </button>


                <button 
                  class="text-emerald-600 background-transparent px-3 py-1 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  @click="() => confirmReversal = true"
                  :disabled="loaders().reverse"
                  v-if="has('wallet.debit.ability')"
                >
                  Reverse
                </button>

                <button 
                  class="text-red-500 background-transparent px-3 py-1 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
                  v-on:click="toggleModal('transaction', false)"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <transition name="bounce" :duration="{ enter: 500, leave: 800 }">
        <div v-if="modals.transaction" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-30 h-screen top-0 left-0 bg-black"></div>
      </transition>
    </transition-group>
    
    <filter-modal 
      :selects="{
        category: category ? [category] : transactionList, 
        status: statusList || []
      }"
      :dates="{
        from: 'Start Date',
        to: 'End Date'
      }"
      :feilds="['amount']"
      :searchable="true"
      :sizable="true"
      :visible="modals.filter"
      @close="modals.filter=false" 
      title="transaction"
      @filter="filter"
      :loading="loading || loaders().fetchTransactions"
    ></filter-modal>

  </div>
</template>
<script>
// import TransDropdown from "@/components/Dropdowns/TransDropdown.vue";
import CircularPagination from "@/components/Paginations/CircularPagination.vue";
import bootstrap from "@/assets/img/bootstrap.jpg";
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { createToaster } from "@meforma/vue-toaster";
import moment from "moment"
import RecursiveTable from '@/components/Tables/RecursiveTable.vue';
const toaster = createToaster({ /* options */ });
// import datepicker from 'js-datepicker'
import FilterModal from "@/components/Modals/FilterModal.vue";
import EmptyList from "@/components/Cards/EmptyList.vue";
import PermissionErr from "@/components/Cards/PermissionErr.vue";
import { confirmAction, snakeToTitleCase, transactionList, formatter, statusList} from '@/utils'
import { modal } from '@/mixins'

export default {
  data() {
    return {
      bootstrap,
      pageSize: 10,
      params: "",
      loading: false,
      get_csv: false,
      pagination: {},
      transactionList,
      statusList,
      modals: {},
      transaction: {},
      confirmReversal: false,
      confirmReference: "",
      showConfirmation: false,
      from: null,
      to: null,
      hide_commission: true
    };
  },
  components: {
    // TransDropdown,
    RecursiveTable,
    CircularPagination,
    FilterModal,
    EmptyList,
    PermissionErr
  },
  mixins: [modal],
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
    category: {
      type: String,
      required: false
    },
    canCreate: {
      default: false,
      type: Boolean,
      required: false
    }
  },
  watch: {
    category: function() {
      this.loadInitialData()
    }
  },
  methods: {
    ...mapActions(["getTransactions", "reverse", "confirmWithdraw", "declineWithdraw"]),
    ...mapGetters(["transactions", "loaders"]),
    ...mapMutations(["updateTransactionQueryParams"]),
    loadInitialData() {
      const params = {
        hide_commission: this.hide_commission
      }
      const {search} = this.$route.query;
      if ( search ) {
        params.search = search
      }
      if (this.category) {
        params.category = this.category
      }
      this.getTransactions(params)
    },
    pageChange(pagination) {
      this.pagination = pagination;
      this.fetchNewTransactionPage();
    },
    filter(params) {
      this.params = params;
      this.fetchNewTransactionPage(params);
    },
    fetchNewTransactionPage(p={}) {
      const params = {
        ...this.pagination,
        get_csv: this.get_csv,
        hide_commission: this.hide_commission,
        ...this.params,
        ...p
      }
      if (this.category) {
        params.category = this.category
      }
      if (!params.get_csv) {
        this.updateTransactionQueryParams(params)
        this.$emit('transactionChange', params)
      }
      const self = this
      this.loading = true

      this.getTransactions(params).then( response => {
        if ( self.get_csv ) {
          // create file link in browser's memory
          const href = window.URL.createObjectURL(response);
          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download','transactions.csv'); //or any other extension
          document.body.appendChild(link);
          link.click();
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);

          toaster.success("Report downloaded Successfully");
        }
      }).finally(() => {
        self.get_csv = false
        self.loading = false
      })
    },
    toggleModal(modalName, isvisible) {
      this.modals[modalName] = isvisible
    },
    async reverseTransaction() {
      const transaction = this.transaction;
      if (!this.confirmReversal || transaction.reference !== this.confirmReference) {
        toaster.error("Please confirm the reversal of this transaction")
        return
      }
      await this.reverse(transaction)
    },
    showTransaction(trn, showConfirmation = false) {
      this.transaction = {
        ...(JSON.parse(trn.meta) || {}),
        ...(trn.provider_log ? JSON.parse(trn.provider_log) : {}),
        id: trn.id,
        reference: trn.reference,
        platform_status: trn.status,
        desc: trn.description, 
        user_created_at: trn.user?.created_at,
      }; 
      this.confirmReversal = false;
      this.showConfirmation = showConfirmation
      this.toggleModal('transaction', true)
    },
    handleConfirmWithdraw() {
      confirmAction({
        confirmText: "Confirm the confirmation of this transaction",
        successText: "This transaction has been marked as successfull",
        cancelText: "This action was aborted"
      }, () => {
        this.confirmWithdraw(this.transaction).then( () => {
          toaster.success("Successfully confirmed transaction.")
        })
      });
    },
    handleDeclineWithdraw() {
      confirmAction({
        confirmText: "Confirm the declination of this transaction",
        successText: "This transaction has been marked as failed",
        cancelText: "This action was aborted"
      }, () => {
        this.declineWithdraw(this.transaction).then( () => {
          toaster.success("Successfully declined transaction.")
        })
      });
    },
  },
  mounted() {
    this.loadInitialData()
  },
  created() {
    this.moment = moment
    this.formatter = formatter
    this.snakeToTitleCase = snakeToTitleCase
  }
};
</script>
