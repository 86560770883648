<template>
  <div>
    <a
      class="text-blueGray-500 block"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <div class="items-center flex">
        <span
          class="w-12 h-12 text-sm text-blueGray-700 bg-blueGray-200 inline-flex items-center justify-center rounded-full"
        >
          <!-- <img
            alt="..."
            class="w-full rounded-full align-middle border-none shadow-lg"
            :src="image"
          /> -->
          <i class="pi pi-user"></i>
        </span>
      </div>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <router-link
        to="/admin/commissions"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        v-if="has('commissions.view')"
      >
        <i class="pi pi-database" style="font-size: 10px;"></i> Commission
      </router-link>
      <router-link
        to="/admin/settings"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        <i class="pi pi-comments" style="font-size: 10px;"></i> Support
      </router-link>
      <router-link
        to="/admin/settings"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        <i class="pi pi-user-edit" style="font-size: 10px;"></i> Profile
      </router-link>
      <div class="h-0 my-2 border border-solid border-blueGray-100" />
      <a
        href="javascript:void(0);"
        @click="logoutUser"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        <i class="pi pi-sign-out" style="font-size: 10px;"></i> Logout
      </a>
    </div>
    <div v-if="dropdownPopoverShow" @click="toggleDropdown($event)" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-30 h-screen top-0 left-0 bg-black"></div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import { mapActions } from "vuex";
import image from "@/assets/img/team-1-800x800.jpg";

export default {
  data() {
    return {
      dropdownPopoverShow: false,
      image: image,
    };
  },
  methods: {
    ...mapActions([
      "logout"
    ]),
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
    logoutUser: function() {
      this.logout().then(() => {
        const self = this;
        setTimeout( function(){
          self.$router.push('/auth/login');
        }, 1500);
      }).catch( err => {
        console.log(err)
      })
    }
  },
};
</script>
