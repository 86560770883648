<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-2 max-w-full flex-grow flex-1">
          <h3 class="text-base text-blueGray-700">
            <i class="pi pi-barcode"></i> Plan Subscription List
          </h3>
        </div>
        <div
          class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
        >
          <button
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            @click="$emit('filter')"
          >
            <i class="pi pi-filter" style="font-size: 10px;"></i> filter
          </button>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead class="thead-light">
          <tr>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left"
            >
              User
            </th>
            <th
              class="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left"
            >
              Reviews
            </th>
            <th
              class="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left"
            >
              Plan
            </th>
            <th
              class="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left"
            >
              Cluster(s)
            </th>
            <th
              class="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left min-w-140-px"
            >
              Amount
            </th>
            <th
              class="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left min-w-140-px"
            >
              Status
            </th>
            <!-- <th
              class="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left min-w-140-px"
            >
              Profit/Loss
            </th>
            <th
              class="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left min-w-140-px"
            >
              Rate
            </th> -->
            <th
              class="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left min-w-140-px"
            >
              Summary
            </th>
            <th
              class="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left min-w-140-px"
            >
              Date
            </th>
          </tr>
        </thead>
        <tbody v-if="has('user.plan.view')">
          <template v-if="!loaders().fetchPlanUser">
            <tr v-for="(userPlan, i) in planList" :key="i" class="cursor-pointer" @mouseenter="userPlanIdx = i">
              <th
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                :class="{
                  'text-red-500': !userPlan.user?.settings?.is_verified
                }"
              >
                <div class="flex capitalize" @click="() => { user = userPlan.user; toggleModal('user', true)}">
                  {{ userPlan.user?.name }} 
                </div>
                <small class="text-blueGray-400 cursor-pointer" @click.prevent="clickToCopy(userPlan.user?.email)">
                  {{ userPlan.user?.email }}  <i v-if="userPlan.user?.email" class="pi pi-clone text-xs" ></i>
                </small>
              </th>
              <th
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                :class="{
                  'text-red-500': !userPlan.user?.settings?.is_verified
                }"
              >
                <review-dropdown :reviews="userPlan.user?.reviews" model="user" :model_id="userPlan.user?.id"></review-dropdown>
              </th>
              <td
                class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 capitalize"
                @click="showUserPlanModal(userPlan)"
              >
                {{ userPlan.plan?.name }}
              </td>
              <td
                class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <template v-if="(userPlan.user?.clusters || []).length > 0">
                  <span v-for="(cluster, j) in (userPlan.user?.clusters || [])" class="text-blueGray-400" :class="{
                    'text-emerald-600': cluster.status === 'APPROVED',
                    'text-blueGray-700': cluster.status === 'PENDING',
                    'text-orange-500': cluster.status === 'PARTIALLY_BLOCKED'
                  }" :key="j">
                    <span @click.prevent="showCluster(userPlan, cluster)">{{ cluster.name }}</span> | 
                    <span @click.prevent="clickToCopy(cluster.code)">{{ cluster.code || 'nill' }} <i v-if="cluster.code" class="pi pi-clone text-xs" ></i></span> 
                  </span>
                </template>
                <span v-else class="text-red-500">
                  <i>no cluster</i>
                </span>
              </td>
              <td
                class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                @click="showUserPlanModal(userPlan)"
              >
                <div class="flex items-center">
                  <small class="text-xs">{{ formatter(userPlan.currency).format(userPlan.amount) }}</small>
                  <!-- <span class="px-2 py-1 bg-warmGray-400 rounded-lg" v-for="(tag, j) in JSON.parse(plan.risk_type)" :key="j">{{ tag }}</span> -->
                </div>
              </td>
              <td
                class="border-t-0 px-2 align-middle Capitalize border-l-0 border-r-0 text-xs whitespace-nowrap p-4 cursor-pointer"
                @click="selectPlan(userPlan)"
                :class="{
                  'text-emerald-500': userPlan?.status.toUpperCase() === 'ACTIVE',
                  'text-red-500': userPlan?.status.toUpperCase() === 'TERMINATED',
                  'text-orange-500': userPlan?.status.toUpperCase() === 'INITIATED',
                  'text-blueGray-400': userPlan?.status.toUpperCase() === 'PENDING'
                }"
              >
                {{ userPlan?.status }}
              </td>
              <td
                class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 overflow-hidden"
              >
                <div v-if="['PENDING', 'INITIATED', 'APPROVED'].includes(userPlan?.status)">
                  <button
                    class="bg-emerald-500 text-white active:bg-emerald-600 text-xs px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    @click.self="processPlan(userPlan, 'disburse', 'disbursement')"
                    v-if="has('loan.confirm') && userPlan?.status === 'APPROVED'"
                  >
                    <i class="pi pi-check-square"></i> disburse
                  </button>
                  <button
                    class="bg-emerald-500 text-white active:bg-emerald-600 text-xs px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    @click.self="processPlan(userPlan, 'approved', 'approval')"
                    v-else-if="userPlan?.status === 'INITIATED' || userPlan?.status === 'PENDING'"
                  >
                  <i class="pi pi-check-circle"></i> approve
                  </button>
                  <button
                    class="bg-red-500 text-white active:bg-red-600 text-xs px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    @click.stop="processPlan(userPlan, 'decline')"
                    v-if="has('plan.terminate.ability') || has('plan.terminate')"
                  >
                  <i class="pi pi-times-circle"></i> decline
                  </button>
                </div>
                <div 
                v-else-if="['ACTIVE', 'COMPLETED', 'RETRY', 'DUE', 'DEFAULT'].includes(userPlan?.status)" class="flex flex-inline"
                @click="selectPlan(userPlan)"
                >
                  <template v-if="userPlan.loans && userPlan.loans.length > 0">
                    <span 
                    v-for="(loan, i) in userPlan.loans.slice(0, 7)" :key="i" 
                    class="w-5 h-5 rounded-full border-2 border-blueGray-50 shadow" 
                    :class="`${loan?.status === 'PAID' ? 'bg-emerald-600' : 'bg-red-600'}`"
                    :title="loan.status"
                    ></span>...
                  </template>
                  <template v-else-if="userPlan.savings && userPlan.savings.length > 0">
                    <span 
                    v-for="(save, i) in userPlan.savings.slice(0, 7)" 
                    :key="i" 
                    class="w-5 h-5 rounded-full border-2 border-blueGray-50 shadow" 
                    :class="{
                      'bg-emerald-600': save.status === 'PAID',
                      'bg-orange-500': save.status === 'WITHDRAWN',
                      'bg-red-600': !['PAID', 'WITHDRAWN'].includes(save.status)
                    }"
                    :title="loan?.status || 'none'"
                    ></span> ...
                  </template>
                  <template v-else-if="userPlan.investments && userPlan.investments.length > 0">
                    <span 
                    v-for="(investment, i) in userPlan.investments.slice(0, 7)" 
                    :key="i" 
                    class="w-5 h-5 rounded-full border-2 border-blueGray-50 shadow" 
                    :class="`${investment.status === 'PAID' ? 'bg-emerald-600' : 'bg-red-600'}`"
                    :title="loan?.status || 'none'"
                    ></span>...
                  </template>
                  <template v-else>
                    <i>no payment plan</i>
                  </template>
                </div>
                <button
                  class="bg-indigo-500 text-white active:bg-indigo-600 text-xs px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  @click="selectPlan(userPlan)"
                >
                  <i class="pi pi-eye"></i> see more
                </button>
              </td>
              <td
                class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                {{ moment(userPlan.created_at).format("DD MMM YY") }}
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="8" rowspan="8">
                <div class="flex items-center justify-center w-full py-12 h-30">
                  <span class="loader block"></span>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <permission-err v-if="!has('user.plan.view')"></permission-err>
      <empty-list v-if="(!planList || planList.length === 0) && !loaders().fetchPlanUser && has('user.plan.view')"></empty-list>
    </div>
    <div class="flex flex-wrap items-center">
      <div class="relative w-full px-4 max-w-full flex-grow flex-1">
        <!-- {{ plans }} -->
        <circular-pagination :current_page="plans.current_page" :last_page="plans.last_page" :size="pageSize" @paginate="pageChange" ></circular-pagination>
      </div>
    </div>

    <transition name="bounce" :duration="{ enter: 500, leave: 800 }">
      <div v-if="modals.user" style="width: 100%;" @click.self="$_close_all_modal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
        <div class="relative w-auto z-50">
          <!--content-->
          <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <!--header-->
            <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 class="text-lg capitalize">
                <i class="pi pi-info-circle"></i> {{  user?.name }} information
              </h3>
              <button 
                class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
                v-on:click="toggleModal('user', false)"
              >
                <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            <!--body-->
            <recursive-table 
              :data="user" 
              :excludeAll="['id', 'is_password_auto_gen', 'usd_wallet', 'user_id']"
              :imagesAll="['logo', 'banner', 'image']"
              :telsAll="['phone']"
              :tels="['profile.phone']"
              :emailsAll="['email', 'support_email']"
              :datesAll="['created_at', 'updated_at', 'email_verified_at']"
              :copies="['phone', 'profile.phone']" 
            />
            <div class="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b">            
              <button 
                class="text-red-500 background-transparent px-3 py-1 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
                v-on:click="toggleModal('user', false)"
              >
                close
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="bounce" :duration="{ enter: 500, leave: 800 }">
      <div v-if="modals.cluster" style="width: 100%;" @click.self="$_close_all_modal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
        <div class="relative w-auto z-50">
          <!--content-->
          <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <!--header-->
            <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 class="text-lg">
                <i class="pi pi-info-circle"></i> Cluster {{  cluster.name }}
              </h3>
              <button 
                class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
                v-on:click="toggleModal('cluster', false)"
              >
                <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            <!--body-->
            <recursive-table :data="cluster" :exclude="['id', 'pivot']" :copies="['code']" />
            <div class="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b">
              <button
                class="bg-red-500 text-white active:bg-red-600 text-xs px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                @click="handleRemoveFromCluster"
              >
                Revome {{ plan.user?.name }} from cluster
              </button>
              
              <button 
                class="text-red-500 background-transparent px-3 py-1 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
                v-on:click="toggleModal('cluster', false)"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div v-if="modals.cluster || modals.user" @click="$_close_all_modal" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-30 h-screen top-0 left-0 bg-black"></div>
    
    <user-plan-modal :userPlan="plan" :show="modals.userPlan" @close="() => modals.userPlan = false"></user-plan-modal>
  </div>
</template>

<script>
import CircularPagination from "@/components/Paginations/CircularPagination.vue";
import UserPlanModal from "@/components/Modals/UserPlanModal.vue";
import { mapActions, mapGetters } from 'vuex';
import { formatter } from "../../utils/formatter.util";
import moment from "moment"
import ReviewDropdown from "@/components/Dropdowns/UserReviewDropdown.vue";
import { clickToCopy, confirmAction } from "@/utils";
import RecursiveTable from '@/components/Tables/RecursiveTable.vue';
import EmptyList from "@/components/Cards/EmptyList.vue";
import PermissionErr from "@/components/Cards/PermissionErr.vue";
import { modal } from '@/mixins'

export default {
  components: {
    CircularPagination,
    UserPlanModal,
    ReviewDropdown,
    RecursiveTable,
    EmptyList,
    PermissionErr
  },
  props: ["plans", "param"],
  created() {
    this.moment = moment
    this.formatter = formatter
    this.clickToCopy = clickToCopy
  },
  mixins: [modal],
  data() {
    return {
      planList: [],
      plan: {},
      pagination: {},
      modals: {},
      userPlanIdx: null,
      cluster: {},
    }
  },
  watch: {
    plans: function (n) {
      this.planList = n?.data || []
    }
  },
  mounted() {
      this.planList = this.plans?.data || []
  },
  methods: {
    ...mapActions(["fetchPlanUser", "confirmLoan", "updatePlanUser", "leaveBusiness"]),
    ...mapGetters(["loaders"]),
    selectPlan(plan) {
      if (this.has("plans.show")) {
        this.$emit('selectedPlan', plan)
      }
    },
    pageChange(pagination) {
      this.pagination = pagination;
      this.fetchNewPageUser();
    },
    getPlanBalance(plan) {
        return plan.user_purchased_coins.reduce( (t, coin) => t + ( coin.price_sold ? Math.round((parseFloat(coin.price_sold) - parseFloat(coin.price_bought)) * 10)/10 : 0), 0 )
    },
    getPlanSpent(plan) {
        return plan.user_purchased_coins.reduce( (t, coin) => t + ( coin.price_sold ? Math.round(parseFloat(coin.price_bought) * 10)/10 : 0), 0 )
    },
    fetchNewPageUser() {
      const params = {
        ...this.pagination,
        ...this.param
      }
      this.fetchPlanUser(params)
    },
    processPlan(plan, status, adj) {
      confirmAction({
        confirmText: `${plan?.user?.name } ${plan.name } ${ plan.type}`,
        confirmTextHelper: `Kindly confirm you want to ${status} this loan`,
        successText: `You have successfully ${status} ${plan?.user?.name} ${plan.name} ${ plan.type}`,
        cancelText: `${adj || status} of plan aborted`
      }, () => {
        const initialStatus = plan.status;
        const newStatus = status.toUpperCase()
        this.planList = this.planList.map(p => p.id === plan.id ? {...p, status: newStatus} : p);
        const data = {...plan, status: newStatus}

        const query = status === 'disburse' ? this.confirmLoan(plan) : this.updatePlanUser(data)
        query.then(() => {})
        .catch( () => this.planList = this.planList.map(p => p.id === plan.id ? {...p, status: initialStatus} : p))
      });
    },
    showUserPlanModal: function(plan = {}){
      this.plan = plan;
      this.modals.userPlan = true;
    },
    showCluster: function(plan = {}, cluster = {}){
      this.plan = plan;
      this.cluster = cluster
      this.modals.cluster = true;
    },
    toggleModal(modalName, isvisible) {
      this.modals[modalName] = isvisible
    },
    async handleRemoveFromCluster() {
      if (!confirm("Are you sure you want to remove this user from this cluster?")){
        return
      }
      await this.leaveBusiness({userPlan: this.plan, code: this.cluster.code})
    }
  }
}
</script>

<style scoped>
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>