<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-2 max-w-full flex-grow flex-1">
          <h3 class="text-base text-blueGray-700">
            <i class="pi pi-barcode"></i> Dispute Logs
          </h3>
        </div>
        <div
          class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
        >
          <button
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            :disabled="loading"
            @click="toggleModal('filter', true)"
          >
            <i class="pi pi-filter" style="font-size: 10px;"></i> filter
          </button>

          <!-- <button
            class="bg-emerald-500 text-white active:bg-emerald-600 text-xs px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            @click="$emit('create', {})"
          >
            <i class="pi pi-plus-circle" style="font-size: 10px;"></i> Log new dispute
          </button> -->
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead class="thead-light">
          <tr>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left"
            >
              User
            </th>
            <th
              class="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left"
            >
              Type
            </th>
            <th
              class="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left"
            >
              Amount
            </th>
            <th
              class="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left"
            >
              Reference
            </th>
            <th
              class="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left min-w-140-px"
            >
              Status
            </th>
            <th
              class="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left min-w-140-px"
            >
              Date
            </th>
            <th
              class="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left min-w-140-px"
            >
              Details
            </th>
          </tr>
        </thead>
        <tbody v-if="has('disputes.show')">
          <template v-if="!loaders().fetchDisputes">
            <tr v-for="(dispute, i) in disputeList" :key="i" class="cursor-pointer">
              <th
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                :class="{
                  'text-emerald-500': !dispute?.is_active
                }"
              >
                <span class="font-light">
                  {{ dispute?.user?.name }} <br/>
                  <small>{{ dispute?.user?.email }}</small>
                </span>
              </th>
              <th
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
              >
                <span class="font-light lowercase">
                  {{ snakeToTitleCase(dispute.transaction?.category) }} 
                  <small v-if="['CREDIT', 'DEBIT'].includes(dispute?.transaction?.type)"
                  class="uppercase"
                  :class="[
                    dispute?.transaction.type === 'CREDIT' ? 'text-emerald-600' : '',
                    dispute?.transaction.type === 'DEBIT' ? 'text-orange-500' : '',
                    dispute?.transaction.type === 'NONE' ? 'text-blueGray-600' : '',
                  ]"
                  >({{ {"CREDIT": "CR", "DEBIT": "DR"}[dispute?.transaction?.type] }})</small>
                </span>
              </th>
              <td
                class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                {{ formatter(dispute.transaction.currency).format(dispute.transaction.gross_amount) }}
              </td>
              <td
                class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                {{ dispute.transaction.reference }}
              </td>
              <td
                class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <span 
                  class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
                  :class="{
                    'bg-emerald-300 text-emerald-600 ring-emerald-500/20': ['SUCCESSFUL', 'RESOLVED', 'CLOSED'].includes(dispute.status), 
                    'bg-red-200 text-red-600 ring-red-500/10': ['CREATED', 'OPEN'].includes(dispute.status),
                    'bg-blueGray-300 text-blueGray-600 ring-blueGray-500/10': ['PENDING', 'PROGRESS'].includes(dispute.status)
                  }"
                >
                  {{ dispute.status }}
                </span>
              </td>
              <td
                class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                {{ moment(dispute.created_at).format("DD MMM YY") }}
              </td>
              <td
                class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <button
                  class="bg-emerald-500 text-white active:bg-emerald-600 text-xs px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  @click="handleView(dispute)"
                >
                  <i class="pi pi-eye"></i> details
                </button>
                <button
                  class="bg-indigo-500 text-white active:bg-indigo-600 text-xs px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  @click="$emit('create', dispute)"
                  v-if="has('disputes.update')"
                >
                  <i class="pi pi-file-edit"></i> action
                </button>
                <button
                  class="text-xs px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  :class="{
                    'bg-red-500 active:bg-red-600 text-white': dispute.is_active,
                    'bg-blueGray-200 active:bg-blueGray-200 text-black': !dispute.is_active
                  }"
                  @click.stop="handleResolveDispute(dispute)"
                  v-if="has('disputes.toggle.ability')"
                >
                <i class="pi pi-times-circle"></i> {{ dispute.is_active ? 'resolve' : 'open' }}
                </button>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="7" rowspan="7">
                <div class="flex items-center justify-center w-full py-12 h-30">
                  <span class="loader block"></span>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <permission-err title="disputes" v-if="!has('disputes.show')"></permission-err>
      <empty-list v-if="(!disputes.data || disputes.data.length === 0) && !loaders().fetchDisputes && has('disputes.show')"></empty-list>

    </div>
    <div class="flex flex-wrap items-center">
      <div class="relative w-full px-4 max-w-full flex-grow flex-1">
        <circular-pagination :current_page="disputes.current_page" :last_page="disputes.last_page" :size="pageSize" @paginate="pageChange" ></circular-pagination>
      </div>
    </div>

    <transition name="bounce" :duration="{ enter: 500, leave: 800 }">
      <div v-if="modals.dispute" style="width: 100%;" @click.self="$_close_all_modal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
        <div class="relative w-auto z-50">
          <!--content-->
          <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <!--header-->
            <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 class="text-lg capitalize">
                <i class="pi pi-info-circle"></i> Dispute information
              </h3>
              <button 
                class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
                v-on:click="toggleModal('dispute', false)"
              >
                <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            <!--body-->
            <recursive-table 
              :data="dispute"
              :excludeAll="['id']"
              :exclude="['role_id']"
            />
            <div class="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b">            
              <button 
                class="text-red-500 background-transparent px-3 py-1 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
                v-on:click="toggleModal('dispute', false)"
              >
                close
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div v-if="modals.dispute" @click="$_close_all_modal" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-30 h-screen top-0 left-0 bg-black"></div>
    
    <filter-modal 
      :selects="{
        category: transactionList,
        status: ['CREATED', 'OPEN', 'CLOSED', 'RESOLVED', 'SUCCESSFUL', 'PROGRESS']
      }"
      :dates="{
        from: 'Start Date',
        to: 'End Date'
      }"
      :searchable="true"
      :sizable="true"
      :visible="modals.filter"
      @close="modals.filter=false" 
      title="disputes"
      @filter="filter"
      :loading="loading || loaders().fetchDisputes"
    ></filter-modal>

  </div>
</template>

<script>
import CircularPagination from "@/components/Paginations/CircularPagination.vue";
import { mapActions, mapGetters } from 'vuex';
import moment from "moment"
import { confirmAction, transactionList, snakeToTitleCase, formatter } from "@/utils";
import RecursiveTable from '@/components/Tables/RecursiveTable.vue';
import EmptyList from "@/components/Cards/EmptyList.vue";
import PermissionErr from "@/components/Cards/PermissionErr.vue";
import FilterModal from "@/components/Modals/FilterModal.vue";
import { modal } from '@/mixins'

export default {
  components: {
    CircularPagination,
    RecursiveTable,
    EmptyList,
    PermissionErr,
    FilterModal
  },
  mixins: [modal],
  props: ["disputes", "param"],
  created() {
    this.moment = moment
    this.formatter = formatter
    this.snakeToTitleCase = snakeToTitleCase
  },
  data() {
    return {
      disputeList: [],
      dispute: {},
      pagination: {},
      modals: {},
      transactionList
    }
  },
  watch: {
    disputes: function (n) {
      this.disputeList = n?.data || []
    },
    '$store.state.disputes.data': function (n) {
      this.disputeList = n
    }
  },
  computed: {
    ...mapGetters(["roles"]),
  },
  mounted() {
    this.disputeList = this.disputes?.data || []
  },
  methods: {
    ...mapActions(["fetchDisputes", "toggleDispute"]),
    ...mapGetters(["loaders"]),
    pageChange(pagination) {
      this.pagination = pagination;
      this.fetchNewPageDispute();
    },
    filter(params) {
      this.params = params;
      if (this.params.search) {
        this.params.reference = this.params.user = this.params.search
      }
      this.fetchNewPageDispute(params);
    },
    fetchNewPageDispute(p={}) {
      const params = {
        ...this.pagination,
        get_csv: this.get_csv,
        ...this.params,
        ...p
      }

      this.fetchDisputes(params)
    },
    toggleModal(modalName, isvisible) {
      this.modals[modalName] = isvisible
    },
    async handleResolveDispute(dispute) {
      confirmAction({
        confirmText: `${dispute.is_active ? 'Closing' : 'Opening'} ${dispute.transaction.reference}`,
        confirmTextHelper: `Kindly confirm you want to ${dispute.is_active ? 'close' : 'open'} this dispute`,
        successText: `You have successfully ${dispute.is_active ? 'closed' : 'opened'} ${dispute.transaction.reference} ${dispute.transaction.type} dispute`,
        cancelText: `${dispute.is_active ? 'Closing' : 'Opening'} of dispute aborted`
      }, () => {
        this.toggleDispute(dispute).then(() => {})
      });
    },
    handleView(dispute) {
      if (dispute.transaction?.meta && typeof dispute.transaction?.meta === "string") {
        dispute.transaction.meta = JSON.parse(dispute.transaction.meta)
      }
      if (dispute.transaction?.provider_log && typeof dispute.transaction?.provider_log === "string") {
        dispute.transaction.provider_log = JSON.parse(dispute.transaction.provider_log)
      }
      this.dispute = dispute;
      this.toggleModal('dispute', true)
    }
  }
}
</script>

<style scoped>
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>