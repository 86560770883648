import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });

export const images = {
    methods: {
        $_get_image_url(img){
            let knownTypes = {
                '/': 'data:image/jpg;base64,',
                'i': 'data:image/png;base64,',
                'R': 'data:image/gif;base64,',
                'U': 'data:image/webp;base64,',
            }
            
            return !knownTypes[img[0]] ? img : knownTypes[img[0]]+img;
        }
    }
}

export const strings = {
    methods: {
        $_click_to_copy(text){
            navigator.clipboard.writeText(text).then(function() {
              toaster.success('Copied to clipboard!');
            }, function(err) {
              toaster.show('Could not copy text: ', err);
            });
        }
    }
}

export const modal = {
    methods: {
        $_close_all_modal(){
            // console.log(this.modals)
            Object.keys(this.modals).forEach(key => {
                this.modals[key] = false;
            });
        }
    }
}