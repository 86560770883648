<template>
  <div>
    <a
      class="text-blueGray-500 py-1 px-3"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <i class="fas fa-ellipsis-v"></i>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <a
        href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        @click="updateLimit()"
        :class="{'text-emerald-500': isBlocked, 'text-blueGray-600': !isBlocked}"
      >
        <i class="pi text-xs" :class="{'pi-ban': !isBlocked, 'pi-sign-in': isBlocked}"></i> {{ isBlocked ? 'Un-block': 'Block' }} User
      </a>
      <a
        href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        @click="initResetPin()"
      >
        <i class="pi pi-sync text-xs"></i> Reset NGN Wallet Pin
      </a>
      <a
        href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        @click="(e) => {$emit('debit-click', e); toggleDropdown(e)}"
        v-if="has('wallet.debit.ability')"
      >
        <i class="pi pi-credit-card text-xs"></i> Debit Wallet
      </a>
      <a
        href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        @click="(e) => {$emit('view-info-click', e); toggleDropdown(e)}"
      >
        <i class="pi pi-eye text-xs"></i> View More
      </a>
    </div>
    <div v-if="dropdownPopoverShow" @click="toggleDropdown($event)" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-30 h-screen top-0 left-0 bg-black"></div>
  </div>
</template>
<script>
import { createPopper } from "@popperjs/core";
import { mapActions, mapGetters } from 'vuex';
import { confirmAction } from "@/utils";
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });

export default {
  props: ["user"],
  data() {
    return {
      dropdownPopoverShow: false,
      isBlocked: false
    };
  },
  methods: {
    ...mapActions(["unBlockUser", "blockUser", "transactionPinReset"]),
    ...mapGetters([]),
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
    updateLimit: function() {
      if ( this.isBlocked && !this.has('user.unblock.create')) {
        toaster.show("you do not have the permission to un-block users")
        return
      } else if ( !this.isBlocked && !this.has('user.block.create')) {
        toaster.show("you do not have the permission to block users")
        return
      }

      confirmAction({
        confirmText: `${this.blockUser ? 'Un-Block' : 'Block'} ${this.user.name}`,
        confirmTextHelper: `Kindly confirm you want to ${this.blockUser ? 'un-block' : 'block'} ${this.user.name}`,
        successText: `You have successfully ${this.blockUser ? 'un-blocked' : 'blocked'} ${this.user.name}`,
        cancelText: `You have aborted this action`
      }, () => {
        const q = this.isBlocked ? this.unBlockUser(this.user.id) : this.blockUser(this.user.id)
        const self = this;
        q.then(() => {
          self.isBlocked = !self.isBlocked
        });
      });
    },
    initResetPin() {
      confirmAction({
        confirmText: `Reset ${this.user.name}'s transaction pin`,
        confirmTextHelper: `Kindly confirm you want to reset ${this.user.name}'s pin.`,
        successText: `You have successfully sent a reset pin to ${this.user.name}. This pin cannot be used to transact. User can only use it to set a new pin`,
        cancelText: `You have aborted this action`
      }, () => {
        this.transactionPinReset({
          user_id: this.user.id,
          currency: "NGN"
        })
      });
    }
  },
  mounted() {
    this.isBlocked = this.user.is_blocked
  }
};
</script>
