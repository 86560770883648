<template>
  <div>
    <a
      class="text-orange-500 py-1 px-3"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <i class="fas fa-star"></i> {{ modelRatings }}
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <div>
        <h4 class="px-4">Review Here</h4>
        <form @keyup.enter="reviewModel">
          <div class="flex flex-wrap">
            <div class="w-full lg:w-4/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Rate
                </label>
                <!-- <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                  v-model="v$.review.ratings.$model"
                /> -->
                <i 
                  class="fas fa-star mx-1 cursor-pointer"
                  :class="{'text-orange-500': review.ratings && i <= review.ratings}"
                  @click="selectRate(i)" 
                  v-for="(i) in [1, 2, 3, 4, 5]" :key="i"></i>
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.review.ratings.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>

            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Comment
                </label>
                <textarea
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                  rows="4"
                  v-model="v$.review.comment.$model"
                ></textarea
                >
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.review.comment.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>

            <div class="relative w-full mb-3 px-4 mt-3 justify-right flex">
              <button
                class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button" 
                @click="reviewModel" 
                :disabled="loaders().addReview"
              >
                Review
              </button>
            </div>
          </div>
        </form>
      </div>
      <hr/>
      <div class="mx-4 my-8 font-light">
        <h4>Reviews</h4>
        <div class="text-blueGray-500 text-xs mt-4 border-t-1" v-for="(review, i) in modelReview" :key="i">
          <small class="text-emerald-500 my-2">
            <b>{{ review.author?.name }}</b>
          </small>
          {{ review.comment }}
          <br/>
          <i 
            class="fas fa-star mx-1 cursor-pointer"
            :class="{'text-orange-500': review.ratings && i <= review.ratings}"
            @click="selectRate(i)" 
            v-for="(i) in [1, 2, 3, 4, 5]" :key="i"></i> 
          {{ review.ratings }}
        </div>
      </div>
    </div>
    <div v-if="dropdownPopoverShow" @click="toggleDropdown($event)" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-30 h-screen top-0 left-0 bg-black"></div>
  </div>
</template>
<script>
import { createPopper } from "@popperjs/core";
import { mapActions, mapGetters } from 'vuex';
// import { createToaster } from "@meforma/vue-toaster";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
// const toaster = createToaster({ /* options */ });

export default {
  props: ["reviews", "model", "model_id"],
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      dropdownPopoverShow: false,
      modelRatings: 0,
      review: {}
    };
  },
  validations() {
    return {
      review: {
        ratings: { required },
        comment: { required }
      }
    }
  },
  mounted() {
    this.modelReview = this.reviews || []
    if (!this.reviews || this.reviews.length === 0) {
      return 5.0
    }
    const sum = this.reviews.reduce((t, v) => (t + (+v.ratings)), 0);
    this.modelRatings = sum === 0 ? 0.0 : (sum / this.reviews.length).toFixed(1) 
  },
  // watch: {
  //   modelReview: function(n) {
  //     console.log(n)
  //     if (!n) {
  //       return 0.0
  //     }
  //     const sum = n.reduce((t, v) => (t + v.ratings), 0);
  //     this.modelRatings = sum === 0 ? 0.0 : (sum / n.length).toFixed(1)
  //   }
  // },
  methods: {
    ...mapActions(["addReview", "blockUser"]),
    ...mapGetters(["loaders"]),
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
    selectRate: function(i) {
      this.review.ratings = i
    },
    async reviewModel() {
      if ( !this.model || !this.model_id ) {
        return
      }
      const data = {
        ...this.review,
        model: this.model,
        id: this.model_id
      }
      try{
        const review = await this.addReview(data)
        this.review = {}
        this.modelReview = [
          review,
          ...this.modelReview
        ]
      } catch(e) {
        console.log(e)
      }
    }
  }
};
</script>
