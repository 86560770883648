<template>
  <div>
    <div class="flex flex-wrap mt-8">
      <div class="w-full xl:w-8/12 mb-4 xl:mb-0 px-3">
        <card-line-chart :data="transactionData" title="Sales Record" />
      </div>
      <div class="w-full xl:w-4/12 px-3">
        <card-bar-chart :data="userData" title="User Registration" />
      </div>
    </div>

    <div class="flex flex-wrap mt-2">
      <div class="w-full xl:w-12/12 mb-12 xl:mb-0 px-3">
        <card-transactions @transactionChange="loadDashboard"></card-transactions>
      </div>
      <!-- <div class="w-full xl:w-4/12 px-3">
        <card-prediction-summary title="Best Prediction" link="/admin/coins" :data="predictions().first_match.map( f => ({description: f.symbol, value: f.price, change: f.percent_change_1h, logo: f.logo}))" />
      </div> -->
    </div>
  </div>
</template>
<script>
import CardLineChart from "@/components/Cards/CardLineChart.vue";
import CardBarChart from "@/components/Cards/CardBarChart.vue";
import CardTransactions from "@/components/Cards/CardTransactions.vue";
// import CardPredictionSummary from "@/components/Cards/CardPredictionSummary.vue";
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "dashboard-page",
  components: {
    CardLineChart,
    CardBarChart,
    CardTransactions,
    // CardPredictionSummary,
  },
  data() {
    return {
      userData: {},
      transactionData: {}
    }
  },
  mounted(){
    setInterval(() => {
      this.loadDashboard({})
    }, 30_000)
  },
  methods: {
    ...mapActions(["getDashboard"]),
    ...mapGetters(["dashboard", "predictions"]),
    loadDashboard(params = {}) {
      this.getDashboard(params).then(res => {
        const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        const registeredUser = []
        const unverifiedUser = []

        const creditRecord = []
        const debitRecord = []

        // const lastUser = res.users[res.users.length - 1];
        // const lastUserDay = (new Date(lastUser.date)).getDay();

        // for ( let i = days.length; i > 0; i-- ) {
        for ( let i = 0; i < days.length; i++ ) {
          //const idx = ( days.length + lastUserDay + i ) % days.length;
          const userRecord = res.users[i];
          
          if ( userRecord ) {
            registeredUser.push(userRecord.register)
            unverifiedUser.push(userRecord.unverified)
          } else {
            registeredUser.push(0)
            unverifiedUser.push(0)
          }

          const transactionRecord = res.transactions[i];

          if ( transactionRecord ) {
            creditRecord.push(transactionRecord.credit)
            debitRecord.push(transactionRecord.debit)
          } else {
            creditRecord.push(0)
            debitRecord.push(0)
          }

        }

        this.userData = {
          labels: days,
          datasets: [
            {
              label: "Unverified",
              backgroundColor: "#ff9100",
              borderColor: "#ff9100",
              data: unverifiedUser,
              fill: false,
              barThickness: 8,
            },
            {
              label: "Registered",
              fill: false,
              backgroundColor: "#4c51bf",
              borderColor: "#4c51bf",
              data: registeredUser,
              barThickness: 8,
            },
          ],
        };

        this.transactionData = {
          labels: days,
          datasets: [
            {
              label: "Credit",
              backgroundColor: "#4c51bf",
              borderColor: "#4c51bf",
              data: creditRecord,
              fill: false,
            },
            {
              label: "Debit",
              fill: false,
              backgroundColor: "#ff9100",
              borderColor: "#ff9100",
              data: debitRecord,
            },
          ],
        }
      })
    }
  }
};
</script>
