<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-blueGray-700">
            <i class="pi pi-wave-pulse"></i> Transaction
          </h3>
        </div>
        <div
          class="relative w-4/12 px-4 max-w-1/2 flex-grow flex-1 text-right"
        >
          filter: 
          <select @change="fetchNewUserPage('')" v-model="category" :disabled="loaders().fetchTransactions" class="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-xs shadow focus:outline-none ease-linear transition-all duration-150">
            <option v-for='(cur, i) in transactionList' :key="i" >{{ cur }}</option>
          </select>
          <router-link
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs px-4 py-2 ml-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
            to="transactions"
          >
            <i class="pi pi-eye text-xs" style="font-size: 10px;"></i> See More
          </router-link>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Customer
            </th>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Type
            </th>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Amount/<small>Fee</small>
            </th>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Balance
              <small>Before / After</small>
            </th>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Reference
            </th>
            
          </tr>
        </thead>
        <tbody v-if="has('transaction.view')">
          <template v-if="!loaders().fetchTransactions">
            <tr v-for="(trnx, i) in transactions().data" :key="i">
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
              <i class="pi pi-user"></i> {{ trnx.user.name }}<br/>
                <small>({{ trnx.user.email }})</small>
              </td>
              <th
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left cursor-pointer"
                :title="trnx.status"
                @click="transaction = {
                  customer_name: trnx.user.name,
                  customer_email: trnx.user.email,
                  reference: trnx.reference,
                  desc: trnx.description, 
                  user_created_at: trnx.user?.created_at,
                  ...(JSON.parse(trnx.meta) || {})
                }; toggleModal('transaction', true)"
              >
                <span 
                  class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset lowercase"
                  :class="{
                    'bg-emerald-300 text-emerald-600 ring-emerald-500/20': trnx.status === 'SUCCESSFUL', 
                    'bg-red-200 text-red-600 ring-red-500/10': trnx.status === 'FAILED'
                  }"
                >
                  {{ snakeToTitleCase(trnx.category) }}
                </span>
              </th>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <i 
                  class="fas mr-1"
                  :class="{
                    'fa-arrow-up text-emerald-500': trnx.type === 'CREDIT',
                    'fa-arrow-down text-red-500': trnx.type === 'DEBIT',
                    'fa-arrow-right text-blueGray-500': trnx.type === 'NONE'
                  }"
                ></i>
                {{ formatAbbrCurrency({amount: trnx.gross_amount, currency: trnx.currency}) }}/<small>{{ formatAbbrCurrency({amount: trnx.charge, currency: trnx.currency}) }}</small>
              </td>   
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                {{ formatter(trnx.currency).format(JSON.parse(trnx.meta || "{}").balance_before || 0) }} / 
                {{ formatter(trnx.currency).format(JSON.parse(trnx.meta || "{}").balance || 0) }}
              </td>     
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 flex"
                :title="trnx.reference"
              >
                <span class="truncate flex w-1/2" style="width: 60px;">{{ trnx.reference }}</span>...
              </td>
              
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="5" rowspan="5">
                <div class="flex items-center justify-center w-full py-12 h-30">
                  <span class="loader block"></span>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <permission-err v-if="!has('transaction.view')"></permission-err>
      <empty-list 
        v-if="(!transactions().data || transactions().data.length === 0) && !loaders().fetchTransactions && has('transaction.view')"
      ></empty-list>
    </div>
    <transition-group>
        <transition name="bounce" :duration="{ enter: 500, leave: 800 }">
          <div v-if="modals.transaction" @click.self="$_close_all_modal" style="width: 100%;" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
            <div class="relative w-auto z-50">
              <!--content-->
              <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <!--header-->
                <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 class="text-xl font-semibold">
                    Transaction
                  </h3>
                  <button 
                    class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
                    v-on:click="toggleModal('transaction', false)"
                  >
                    <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <!--body-->
                <recursive-table :data="transaction" :exclude="['id']" :copies="['customer_email', 'reference']" />
                <div class="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b">
                  <router-link 
                    class="text-emerald-600 background-transparent capitalize px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    :to="`/admin/transactions?search=${transaction.customer_email}`"
                  >
                    View more {{ transaction.customer_name }} transactions
                  </router-link>

                  <button 
                    class="text-red-500 background-transparent capitalize px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
                    v-on:click="toggleModal('transaction', false)"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <transition name="bounce" :duration="{ enter: 500, leave: 800 }">
          <div v-if="modals.transaction" @click="$_close_all_modal" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-30 h-screen top-0 left-0 bg-black"></div>
        </transition>
    </transition-group>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { transactionList, formatter, snakeToTitleCase } from '../../utils'
import { formatAbbrCurrency } from '../../utils/formatter.util'
import RecursiveTable from '@/components/Tables/RecursiveTable.vue';
import EmptyList from "@/components/Cards/EmptyList.vue";
import PermissionErr from "@/components/Cards/PermissionErr.vue";
import { modal } from '@/mixins'

export default {
  components: {
    RecursiveTable,
    EmptyList,
    PermissionErr
  },
  // props: ["data"],
  data() {
    return {
      category: "",
      transactionList,
      modals: {},
      transaction: {}
    }
  },
  mixins: [modal],
  methods: {
    ...mapActions(["getTransactions", "reverse"]),
    ...mapGetters(["transactions", "loaders"]),
    ...mapMutations(["updateTransactionQueryParams"]),
    fetchNewUserPage(q) {
      this.updateTransactionQueryParams({category: this.category, q})
      this.$emit('transactionChange', {category: this.category, q})
      this.getTransactions({category: this.category, q})
    },
    toggleModal(modalName, isvisible) {
      this.modals[modalName] = isvisible
    }
  },
  mounted() {
    this.getTransactions()
  },
  created() {
    this.formatAbbrCurrency = formatAbbrCurrency
    this.formatter = formatter
    this.snakeToTitleCase = snakeToTitleCase
  }
}
</script>
