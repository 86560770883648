import AccountGetter from "./account.getter";
import LoaderGetter from "./loader.getter";
import QuestionGetter from "./question.getter";
import NotificationGetter from "./notification.getter";
import TransactionGetter from "./transaction.getter";
import UserGetter from "./user.getter";
import PlanGetter from "./plan.getter";
import ConfigGetter from "./config.getter";
import SettingsGetter from "./settings.getter";
import BankAccountGetter from "./bankAccount.getter";
import WithdrawGetter from "./withdraw.getter";
import ToastGetter from "./toast.getter";
import MetricsGetter from "./metrics.getter";
import CurrencyGetter from "./currency.getter";
import RoleGetter from "./role.getter";
import BusinessGetter from "./business.getter";
import CampaignGetter from "./campaign.getter";
import AppGetter from "./app.getter";
import FeeGetter from "./fee.getter";
import VasGetter from "./vas.getter";
import SubscriptionGetter from "./subscription.getter";
import DisputeGetter from "./dispute.getter";

const getters = {
    ...SettingsGetter,
    ...TransactionGetter,
    ...UserGetter,
    ...PlanGetter,
    ...LoaderGetter,
    ...NotificationGetter,
    ...AccountGetter,
    ...QuestionGetter,
    ...ConfigGetter,
    ...BankAccountGetter,
    ...WithdrawGetter,
    ...ToastGetter,
    ...MetricsGetter,
    ...CurrencyGetter,
    ...RoleGetter,
    ...BusinessGetter,
    ...CampaignGetter,
    ...AppGetter,
    ...FeeGetter,
    ...VasGetter,
    ...SubscriptionGetter,
    ...DisputeGetter
};

export default getters;