import http from "./http";

export const FetchDisputes = async params => {
    return await http.get("/disputes", {params});
}

export const SaveDispute = async data => {
    return await http.post("/disputes", data);
}

export const UpdateDispute = async(id, data) => {
    return await http.put(`/disputes/${id}`, data);
}

export const ToggleDispute = async(id, data) => {
    return await http.patch(`/disputes/${id}/toggle`, data);
}

export const DeleteDispute = async id => {
    return await http.delete(`/disputes/${id}`);
}
