<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-blueGray-700">
            <i class="pi pi-folder-open text-sm" style="font-size: 10px;"></i> {{ role.name }} permissions list
          </h3>
        </div>
        <div
          class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
        >
        <button
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            @click="toggleModal('permission', true)"
          >
            <i class="pi pi-plus-circle text-sm" style="font-size: 10px;"></i> add permission
          </button>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto mb-6" v-if="has('role.permission.view')">
      <!-- Projects table -->
      <div class="flex flex-wrap">
        <template v-if="!role.permissions || role.permissions.length === 0">
          <div class="w-full p-4 font-light text-center text-blueGray-400">
            No permission in {{ role.name }} yet!
            <br/>
            Click 
            <button
              class="bg-indigo-500 text-blueGray-700 active:bg-blueGray-100 text-xs px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
             @click="toggleModal('permission', true)"
            >
              <i class="pi pi-plus-circle text-sm" style="font-size: 10px;"></i> add permission
            </button> to add user to this role
          </div>
        </template>
        <div v-else class="w-full md:w-1/2 px-4 flex justify-between mb-2" v-for="(permission, i) in role.permissions" :key="i">
          {{ permission.name }}
          <button v-if="has('role.permission.detach')" class="bg-red-500 text-white active:bg-red-600 uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" @click="removePermission(permission)">
            <!-- detach -->
            <i class="fa fa-times"></i>
          </button>
        </div>
      </div>
    </div>
    <permission-err v-else></permission-err>


    <div v-if="modals.permission" style="width: 100%;" @click.self="$_close_all_modal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
      <div class="relative w-auto z-50">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-lg">
              Add more permission
            </h3>
            <button 
              class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
              v-on:click="toggleModal('permission', false)"
            >
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          
          <form v-if="has('role.permission.attach')">
            <div class="flex flex-wrap">
              <div class="w-full px-4">
                <div class="relative w-full mb-3 mt-3">
                  <label
                    class="block text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    App Permissions
                  </label>
                  
                  <select v-model="v$.permission.$model" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                    <template v-for='(_permission, i) in permissions'>
                      <option v-if="!(role.permissions||[]).includes(_permission.id) && _permission.is_admin" :value="_permission" :key="i">{{ _permission.name }} - <i>({{ _permission.description }})</i></option>
                    </template>
                  </select>
                </div>
              </div> 
            </div>

            <div class="relative w-full mb-3 px-4 mt-2">
              <button
                class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 w-full"
                type="button" 
                @click="attachPermission" 
                :disabled="loaders().addPermission"
                v-if="has('role.permission.attach')"
              >
              <i class="pi pi-plus-circle text-sm" style="font-size: 10px;"></i> Add Permission
              </button>
            </div>
          </form>
          <div v-else class="flex justify-center py-4">
            No sufficient permission to carry out this action
          </div>

          <div class="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b">
            <button 
              class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
              v-on:click="toggleModal('permission', false)"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="modals.permission" @click="$_close_all_modal" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-30 h-screen top-0 left-0 bg-black"></div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { mapActions, mapGetters } from 'vuex';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });
import PermissionErr from "@/components/Cards/PermissionErr.vue";
import { modal } from '@/mixins'

export default {
  props: [
    "selectedRole", 
    "permissions"
  ],
  components: {
    PermissionErr
  },
  mixins: [modal],
  methods: {
    ...mapActions([
      "addPermission", 
      "detachPermission"
    ]),
    ...mapGetters([
      "loaders"
    ]),
    attachPermission() {
      if ( !this.permission.id ) {
        toaster.show('please select a permission first');
        return false;
      }
      const data = {
        role: this.role,
        permission: this.permission
      };
      if ( !this.role.id ) {
        toaster.show('please select a role first');
        return false;
      }

      const self = this;
      this.addPermission(data).then( () => {
        const exisitingPermission = self.role.permissions.find( p => p.id === data.permission.id)
        if ( !exisitingPermission ) {
          self.role.permissions.push(data.permission)
        }
      });
    },
    removePermission(permission) {
      if (!confirm("confirm to delete")) {
        toaster.show("you cancelled the operation");
      }
      const data = {
        role: this.role,
        permission
      };
      if ( !this.role.id ) {
        toaster.show('please select a plan first');
        return false;
      }
      const self = this;
      this.detachPermission(data).then( () => {
        self.role.permissions = self.role.permissions.filter( p => p.id !== data.permission.id)
      });
    },
    toggleModal(modalName, isvisible) {
      this.modals[modalName] = isvisible
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      role: {},
      permission: {},
      modals: {},
    }
  },
  validations() {
    return {
      permission: {}
    }
  },
  watch: {
    // whenever question changes, this function will run
    selectedRole(oldRole) {
      this.role = oldRole;
    },
  },
}
</script>
