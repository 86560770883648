<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full">
      <div class="relative flex flex-col min-w-0 break-words  w-full shadow-lg rounded">
        <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
          <li class="-mb-px mr-2 last:mr-0">
            <a class="text-xs px-5 py-3 shadow-lg rounded leading-normal" v-on:click="toggleTabs(1)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 1, 'text-white bg-orange-500': openTab === 1}">
              <i class="pi pi-dollar"></i> Transactions
            </a>
          </li>
          <li class="-mb-px mr-2 last:mr-0">
            <a class="text-xs px-5 py-3 shadow-lg rounded leading-normal" v-on:click="toggleTabs(2)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 2, 'text-white bg-orange-500': openTab === 2}">
              <i class="pi pi-dollar"></i> Disputes
            </a>
          </li>
          <li class="-mb-px mr-2 last:mr-0">
            <a class="text-xs px-5 py-3 shadow-lg rounded leading-normal" v-on:click="toggleTabs(3)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 3, 'text-white bg-orange-500': openTab === 3}">
              <i class="pi pi-receipt"></i> Payment Logs
            </a>
          </li>
        </ul>
      </div>
      <div class="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded">
        <div class="flex-auto">
          <div class="tab-content tab-space">
            <div v-bind:class="{'hidden': openTab !== 1, 'block': openTab === 1}" class="flex flex-wrap">
              <div class="w-full">
                <card-transaction />
              </div>
            </div>
            <div v-bind:class="{'hidden': openTab !== 2, 'block': openTab === 2}" class="flex flex-wrap">
              <transition name="slide-fade">
                <div class="w-full" :class="{'lg:w-8/12': dispute != null}">
                  <card-dispute-list :disputes="disputes" @create="handleCreate" />
                </div>
              </transition>
              <transition name="slide-fade">
                <div class="w-full lg:w-4/12 px-4" v-if="dispute != null">
                  <card-dispute :selectedDispute="dispute" @close="handleDisputeClose" />
                </div>
              </transition>
            </div>
            <div v-bind:class="{'hidden': openTab !== 3, 'block': openTab === 3}" class="flex flex-wrap">
              <div class="w-full">
                <card-payment-log />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="w-full mb-12 px-4">
      <card-transaction color="dark" />
    </div> -->
  </div>
</template>
<script>
import CardTransaction from "@/components/Cards/CardTransaction.vue";
import CardDisputeList from "@/components/Cards/CardDisputeList.vue";
import CardDispute from "@/components/Cards/CardDispute.vue";
import CardPaymentLog from "@/components/Cards/CardPaymentLog.vue";
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    CardTransaction,
    CardPaymentLog,
    CardDisputeList,
    CardDispute
  },
  data() {
    return {
      openTab: 1,
      dispute: null
    }
  },
  mounted() {
    this.fetchDisputes()
  },
  computed: {
    ...mapGetters(["disputes"]),
  },
  methods: {
    ...mapActions(["fetchDisputes"]),
    handleDisputeClose() {
      this.dispute = null
    },
    handleCreate(dispute = {}) {
      this.dispute = dispute
    },
    toggleTabs: function(tabNumber){
      this.openTab = tabNumber
    }
  }
};
</script>


<!-- [
  "webauthn.login",
  "webauthn.auth.options",
  "webauthn.auth",
  "webauthn.create",
  "webauthn.store.options",
  "webauthn.store",
  "webauthn.destroy",
  "webauthn.update",
  "vend-data.subscribe.ability",
  "disputes.index",
  "disputes.store",
  "disputes.show",
  "disputes.update",
  "disputes.destroy",
  "disputes.toggle.ability"
] -->